<template>
	<div >
        Old Invoice page
    </div>

</template>

<script>
export default {
	data() {
		return {
			
        }
	},
	methods:{
	}
}
</script>

<style scoped>

</style>
